import { graphql, navigate } from 'gatsby';

import DynamicZone from '../../components/dynamic-zone/dynamic-zone';
import FloatingButton from '../../components/floating-button/floating-button';
import Layout from '../../components/layout';
import React from 'react';

const BillonSolutionsPage = (props) => {
    const components = props.data.cms.billonSolutionsPageTemplate?.components || null;

    if (!components) {
        if (typeof window !== `undefined`) {
            navigate(`${props.pageContext.locale === `pl` ? `/pl` : ``}/404`);
        }
        return null;
    }

    return (
        // Passing locale "pl" to make sure that cookie bar gets polish text
        <Layout location={props.location} locale={`pl`} linksColor={`black`}>
            <FloatingButton />
            <DynamicZone components={components} />
        </Layout>
    );
};

export default BillonSolutionsPage;

export const query = graphql`
query ($locale: String!){
  cms {
    billonSolutionsPageTemplate(locale: $locale) {
      components {
        ... on STRAPI_ComponentComponentsSectionWithSquare {
          __typename
          mainTitle
          squareTitle
          description
          companyDataLabel
          companyData
          squareItem {
            squareItemContent
          }
          topPusherHeightMobile
          topPusherHeight
        }
        ... on STRAPI_ComponentComponentsPdfDownload {
          __typename
          topPusherHeightMobile
          topPusherHeight
          subtitle
          sectionTitle {
            tailwindClasses
            title
            ytLink
            style
            description
          }
          pdf {
            title
            file {
              url
            }
            image {
              url
            }
          }
        }
        ... on STRAPI_ComponentComponentsRichTextOnly {
          __typename
          topPusherHeightMobile
          topPusherHeight
          richTextOnlyContent
        }
        ... on STRAPI_ComponentHorizontalTabsHorizontalTabs {
          __typename
          topPusherHeightMobile
          topPusherHeight
          tabs {
            type
            title
            subtitle
            scrollColor
            lottieData
            link
            labelImage {
              url
            }
            labelColour
            label
            isLabelImageVisibleOnDesktop
            imagesTitles
            imagesMetadata
            images {
              url
            }
            fullWidthImageItemsDirection
            sendButtonLabel
            formFieldsData
          }
          tabsContentColor
          tabHeight
          sectionTitle {
            tailwindClasses
            ytLink
            title
            style
            description
          }
        }
        ... on STRAPI_ComponentSeoSeo {
          __typename
          seoDescription
          seoLink
          seoTitle
          seoImages {
            url
          }
        }
      }
    }
  }
}

`;
